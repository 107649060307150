<template>
    <div>
        <!--begin::appointment-->
        <b-card no-body class="mb-5">
            <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-2 variant="link" class="text-left font-weight-bold text-dark pl-8 pr-8 fs-102 collapsed-with-icon">
                    {{ $t('advanced_filter') }}
                </b-button>
            </b-card-header>
            <b-collapse id="accordion-2" visible accordion="my-accordion" role="tabpanel">
                <b-card-body>
                    <div class="m-form m-form--fit m--margin-bottom-20">
                        <div class="row">
                            <div class="form-group col-md-6 mt-2 mb-2">
                                <label>{{$t('customer_appointment_report.customer')}}</label>
                                <multiselect v-model="customer"
                                             :placeholder="$t('customer_appointment_report.customer')"
                                             label="fullname"
                                             track-by="id"
                                             :options="customers"
                                             :multiple="false"
                                             :taggable="false"
                                             :show-labels="false"
                                             :show-no-options="false"
                                             :show-no-results="false"
                                             @search-change="getCustomers($event)">
                                </multiselect>
                            </div>
                            <div class="form-group col-md-6 mt-2 mb-2">
                                <label>{{$t('customer_appointment_report.assigned_staff')}}</label>
                                <multiselect v-model="added_by"
                                             :placeholder="$t('customer_appointment_report.assigned_staff')"
                                             label="full_name"
                                             track-by="id"
                                             :options="users"
                                             :multiple="false"
                                             :taggable="false"
                                             :show-labels="false"
                                             :show-no-options="false"
                                             :show-no-results="false"
                                             @search-change="getUsers($event)">
                                </multiselect>
                            </div>
                            <div class="form-group col-md-6 mt-2 mb-2">
                                <label for="from_date">{{$t('from_date')}}</label>
                                <input v-model="filters.from_date" type="date" id="from_date" class="form-control">
                            </div>

                            <div class="form-group col-md-6 mt-2 mb-2">
                                <label for="to_date">{{$t('to_date')}}</label>
                                <input v-model="filters.to_date" type="date" id="to_date" class="form-control">
                            </div>

                            <div class="form-group col-md-6 mt-2 mb-2">
                                <label>{{$t('status')}}</label>
                                <select name="" id="status" v-model="filters.status" class="custom-select">
                                    <option v-for="row in status_list" :value="row.id" :key="row.id">
                                        {{ row.title }}
                                    </option>
                                </select>
                            </div>
                            <div class="form-group col-md-6 mt-2 mb-2">
                                <label>{{$t('customer_appointment_report.procedure')}}</label>
                                <multiselect v-model="procedure"
                                             :placeholder="$t('customer_appointment_report.procedure')"
                                             label="name"
                                             track-by="id"
                                             :options="procedures"
                                             :multiple="false" :taggable="true"
                                             :show-labels="false"
                                             :show-no-options="false"
                                             :show-no-results="false">
                                </multiselect>
                            </div>

                            <div class="form-group d-inline-flex col-md-6 mt-10">
                                <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                                    {{$t('display_report')}}
                                </button>
                                <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="resetFilter">
                                    <i class="fas fa-trash-restore"></i> {{$t('reset_search')}}
                                </button>
                            </div>
                        </div>
                    </div>
                </b-card-body>
            </b-collapse>
        </b-card>
        <div class="card card-custom">
            <div class="card-body">
                <div class="row mb-10">
                    <div class="col-12 mt-10" id="summaryTableTitle">
                        <h4 class="text-center">{{ $t('customer_appointment_report.appointments_report_group_by_all')}}</h4>
                        <h5 class="text-center">
                            <span v-if="date_time_now">{{ $t('time') }} {{date_time_now}}</span>
                        </h5>
                        <h5 class="text-center">
                            <span v-if="filters.from_date">{{ $t('from') }} {{filters.from_date}} </span>
                            <span v-if="filters.to_date"> {{ $t('to') }} {{filters.to_date}}</span>
                        </h5>
                    </div>
                    <div class="d-flex col-12 justify-content-end p-4">
                        <b-dropdown right :text="$t('export')" class="mr-2 ml-2">
                            <download-excel
                                    class="dropdown-item"
                                    :fetch="dataReport"
                                    :fields="json_fields"
                                    :name="$t('customer_appointment_report.customer_appointment_report')+'.xls'">
                                <i class="la la-file-excel"></i>{{$t('excel')}}
                            </download-excel>
                            <button class="dropdown-item" @click="printData('summaryTable', 'print')">
                                <span><i class="fa fa-print"></i> <span>{{$t('print')}}</span></span>
                            </button>
                            <button class="dropdown-item" @click="printData('summaryTable', 'pdf')">
                                <span><i class="fa fa-file-pdf"></i> <span>{{$t('export_pdf')}}</span></span>
                            </button>
                        </b-dropdown>

                    </div>
                </div>
                <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">
                </v-server-table>

            </div>
        </div>

      <div class="drowing-table">
        <table class="table table-bordered" id="summaryTable">
          <thead>
          <tr id="myRow">
            <th>{{$t('customer_appointment_report.customer')}}</th>
            <th>{{$t('customer_appointment_report.date')}}</th>
            <th>{{$t('customer_appointment_report.start_time')}}</th>
            <th>{{$t('customer_appointment_report.interval')}}</th>
            <th>{{$t('customer_appointment_report.procedure')}}</th>
            <th>{{$t('customer_appointment_report.status')}}</th>
            <th>{{$t('customer_appointment_report.assigned_staff')}}</th>
            <th>{{$t('customer_appointment_report.notes')}}</th>
          </tr>
          </thead>
          <tbody>
            <tr v-for="(row2, index2) in dataList" :key="'c'+index2">
              <td>{{row2.customer}}</td>
              <td>{{row2.appintment_date}}</td>
              <td>{{row2.start_at}}</td>
              <td>{{row2.interval}}</td>
              <td>{{row2.procedure_name}}</td>
              <td>{{row2.status_name}}</td>
              <td>{{row2.added_by}}</td>
              <td>{{row2.notes}}</td>
            </tr>

          </tbody>
        </table>
      </div>
      <div class="selector-export" id="selectorExport">

      </div>
        <!--end::appointment-->
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";
    import exportPDFInnerAction from "@/core/plugins/export-jspdf-inner-action";

    export default {
        name: "index-client-appointments",
        components: {},
        data() {
            return {
                mainRoute: '/reports/customer-appointment-report',
                mainRouteExport: '/reports/customer-appointment-report/data-list',

                date_time_now: null,
                dataList: [],
                data: [],
                customers: [],
                status_list: [],
                users: [],
                procedures: [],

                filters: {
                    customer_id: null,
                    from_date: null,
                    to_date: null,
                    added_by: null,
                    action: null,
                    status: null,
                    procedure_id: null,
                },
                columns: ['customer', 'appintment_date', 'start_at', 'interval', 'procedure_name', 'status_name', 'added_by', 'notes'],

                customer: null,
                user: null,
                procedure: null,
                added_by: null,


            }
        },
        watch: {
            customer: function (val) {
                if (val) {
                    this.filters.customer_id = val.id;
                } else {
                    this.filters.customer_id = null;
                }
            },
            added_by: function (val) {
                if (val) {
                    this.filters.added_by = val.id;
                } else {
                    this.filters.added_by = null;
                }
            },
            procedure: function (val) {
                if (val) {
                    this.filters.procedure_id = val.id;
                } else {
                    this.filters.procedure_id = null;
                }
            },
        },
        computed: {
            json_fields: function () {
                let fields = {};
                fields[this.$t('customer_appointment_report.customer')] = 'customer';
                fields[this.$t('customer_appointment_report.date')] = 'appintment_date';
                fields[this.$t('customer_appointment_report.start_time')] = 'start_at';
                fields[this.$t('customer_appointment_report.interval')] = 'interval';
                fields[this.$t('customer_appointment_report.procedure')] = 'procedure_name';
                fields[this.$t('customer_appointment_report.status')] = 'status_name';
                fields[this.$t('customer_appointment_report.assigned_staff')] = 'added_by';
                fields[this.$t('customer_appointment_report.notes')] = 'notes';

                return fields;
            },
            options: function () {
                let that = this;
                return {
                    texts: {
                        loadingError: that.$t('Something_went_wrong'),
                        filter: "",
                        filterBy: that.$t('Filter') + ' {column}',
                        count: ' ',
                        filterPlaceholder: that.$t('Search_query'),
                        loading: that.$t('Loading') + "...",
                    },
                    headings: {
                        customer: that.$t('customer_appointment_report.customer'),
                        appintment_date: that.$t('customer_appointment_report.date'),
                        start_at: that.$t('customer_appointment_report.start_time'),
                        interval: that.$t('customer_appointment_report.interval'),
                        procedure_name: that.$t('customer_appointment_report.procedure'),
                        status_name: that.$t('customer_appointment_report.status'),
                        added_by: that.$t('customer_appointment_report.assigned_staff'),
                        notes: that.$t('customer_appointment_report.notes'),
                    },
                    sortable: [],
                    filterByColumn: [],
                    filterable: false,
                    customFilters: [{
                        name: 'alphabet',

                    }],
                    orderBy: {'column': 'id'},

                    alwaysShowPerPageSelect: false,
                    perPage: 10,
                    pagination: {chunk: 5, dropdown: false},
                    skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
                    sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
                    perPageValues: [5, 10, 15, 25],

                    requestFunction(data) {
                        let _params = {
                            ascending: data.ascending,
                            byColumn: data.byColumn,
                            limit: data.perPage,
                            orderBy: data.orderBy,
                            page: data.page,
                            filter: data.query,
                            ...that.filters,
                        }
                        return ApiService.query(`${that.mainRoute}`, {..._params});

                    },
                    responseAdapter(resp) {
                        that.data = resp.data.data.data;
                        return {
                            data: resp.data.data.data,
                            count: resp.data.data.total,
                        }
                    }
                }

            },

        },

        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.clients_reports"),route:'/clients/reports'}, {title: this.$t("MENU.customer_appointment_report")}]);
            
            this.date_time_now = this.$moment().format('DD-MM-YYYY H:mm');
            this.getCustomers();
            this.getStatus();
            this.getUsers();
            this.getProcedures();
        },
        methods: {
            doFilter() {
                this.$refs.table.refresh();
            },
            resetFilter() {
                this.filters.customer_id = null;
                this.filters.from_date = null;
                this.filters.to_date = null;
                this.filters.added_by = null;
                this.filters.action = null;
                this.filters.status = null;
                this.customer = null;
                this.added_by = null;
                this.procedure = null;

                this.$refs.table.refresh();
            },


            getCustomers(filter) {
                if(filter && filter.length >= 3)
                ApiService.get("base/dependency/customers", {params:{filter: filter}}).then((response) => {
                    this.customers = response.data.data;
                });
            },
            getUsers(filter) {
                if(filter && filter.length >= 3)
                ApiService.get("base/dependency/users", {params:{name: filter}}).then((response) => {
                    this.users = response.data.data;
                });
            },
            getStatus() {
                ApiService.get("base/dependency/status_list").then((response) => {
                    this.status_list = response.data.data;
                });
            },
            getProcedures() {
                ApiService.get("base/dependency/procedures").then((response) => {
                    this.procedures = response.data.data;
                });
            },
          dataReport() {
                this.setReportLog('excel', 'customer appointment report');
                return this.getDataList();
            },
          async getDataList() {
            return await ApiService.get(this.mainRouteExport, {params: {...this.filters}}).then(response => {
              this.dataList = response.data.data;
              return response.data.data;
            }).catch((error) => {
              this.$errorAlert(error);
            });
          },
          printData(tableId, type) {
            this.setReportLog('pdf', 'customer appointment report');
            // let divToPrint = document.getElementById(tableId);
            // let divTitleToPrint = document.getElementById(tableId + 'Title');
            // let newWin = window.open("");
            // newWin.document.write("<link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css'>" + divTitleToPrint.outerHTML + divToPrint.outerHTML);
            // setTimeout(() => {
            //     newWin.print();
            //     newWin.close();
            // }, 100)
            let promise = this.getDataList();
            Promise.all([promise]).then(()=>{
              exportPDFInnerAction(tableId , type, this.$t('customer_appointment_report.customer_appointment_report'), this.$t('customer_appointment_report.customer_appointment_report'));
            })

          },
            /**
             * register log
             */
            setReportLog(key, description){
                ApiService.post("reports/system_activity_log/log_type",{
                    key:key,
                    description: description,
                });
            }
        },
    };
</script>
<style>
.html2pdf__overlay{
  opacity: 0 !important;
  z-index: -1000;

}
.drowing-table, .selector-export{
  opacity: 0 !important;
  z-index: -1000 !important;
  width: 0.0001px !important;
  height: 0.0001px !important;
  overflow: auto;
}

</style>